import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Accordion from 'atomic-design/molecules/accordion';
import Taglist from 'atomic-design/molecules/tag-list';
import Container from './styles';

const FaqList = ({ faqList }) => {
  const [tagSelected, setTagSelected] = useState('All');

  const faqs = faqList.map(faq => ({ ...faq.node }));

  const uniqueTags = useMemo(() => {
    const tags = faqs.reduce((acc, faq) => {
      return [...acc, ...faq.tags];
    }, []);
    return [...new Set(tags)];
  }, [faqs]);

  const list = useMemo(() => {
    uniqueTags.unshift('All');
    if (tagSelected === 'All') {
      return faqs;
    }
    return faqs.filter(faq => faq.tags.includes(tagSelected));
  }, [tagSelected]);

  return (
    <Container>
      <Taglist list={uniqueTags} tagSelected={tagSelected} setTagSelected={setTagSelected} />
      <Accordion list={list} />
    </Container>
  );
};

FaqList.propTypes = {
  faqList: PropTypes.array.isRequired,
};

export default FaqList;
