import styled from 'styled-components';

const AccordionList = styled.ul`
  padding: ${({ theme }) => `${theme.spacing.large} 0`};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => `${theme.spacing.small} 0`};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => `${theme.spacing.small} 0`};
  }
`;

export default AccordionList;
