import React from 'react';
import PropTypes from 'prop-types';

import Tag from 'atomic-design/atoms/tag';
import TaglistItemContainer from './styles';

const TaglistItem = ({ item, selected, onClick }) => {
  return (
    <TaglistItemContainer onClick={onClick}>
      <Tag
        title={item}
        variant={selected ? 'contained' : 'outlined'}
        ariaLabel="filter faq by title"
      />
    </TaglistItemContainer>
  );
};

TaglistItem.propTypes = {
  item: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default TaglistItem;
