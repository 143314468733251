import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import TagContainer from './styles';

const Tag = ({ title, variant, ariaLabel }) => {
  return (
    <TagContainer>
      <Button variant={variant} ariaLabel={ariaLabel}>
        {title}
      </Button>
    </TagContainer>
  );
};

Tag.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
  ariaLabel: PropTypes.string,
};

Tag.defaultProps = {
  variant: 'outlined',
  ariaLabel: '',
};

export default Tag;
