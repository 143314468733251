import styled from 'styled-components';

const TaglistItemContainer = styled.li`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.medium};

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    button {
      ${({ theme }) => `padding: ${theme.spacing.smallest} ${theme.spacing.medium}`};
      font-size: ${({ theme }) => theme.fonts.sizes.small};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    button {
      ${({ theme }) => `padding: ${theme.spacing.smallest} ${theme.spacing.medium}`};
      font-size: ${({ theme }) => theme.fonts.sizes.extraSmall};
    }
  }
`;

export default TaglistItemContainer;
