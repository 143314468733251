import styled from 'styled-components';

export const ContainerPageHeader = styled.div`
  padding-top: ${({ theme }) => theme.newTheme.spacing(16)};
  position: relative;
  width: 100%;
  z-index: 9;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    padding-top: ${({ theme }) => theme.newTheme.spacing(6)};
  }
`;

export const PageHeaderTitle = styled.h1`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  color: ${({ theme }) => theme.newTheme.color.grey.light};
  margin: 0;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  }
`;

export const PageHeaderSubtitle = styled.h2`
  font-size: ${({ theme }) => theme.newTheme.font.title1.size};
  line-height: ${({ theme }) => theme.newTheme.font.title1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  margin: ${({ theme }) => theme.newTheme.spacing(10)} 0;
  color: ${({ theme }) => theme.newTheme.color.black};
  width: 80%;

  u {
    text-decoration: none;
    display: block;
  }

  b {
    background: ${({ color }) => color};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    margin: 0;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 100%;
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  }
`;

export const PageHeaderDescription = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  color: ${({ theme }) => theme.newTheme.color.grey.main};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    line-height: ${({ theme }) => theme.lineHeight.secondary};
  }
`;
