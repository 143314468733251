import styled, { css } from 'styled-components';

const titleSize = (size, theme) =>
  ({
    large: css`
      font-size: ${theme.fonts.sizes.headings.heading5};
      @media only screen and (${theme.newTheme.breakpoints.tablet}) {
        font-size: ${theme.newTheme.font.text1.size};
        line-height: ${theme.newTheme.font.text2.lineHeight};
      }
      @media only screen and (${theme.newTheme.breakpoints.mobile}) {
        font-size: ${theme.newTheme.font.text1.size};
        line-height: ${theme.newTheme.font.text2.lineHeight};
      }
    `,

    small: css`
      font-size: ${theme.newTheme.font.text1.size};
      line-height: ${theme.newTheme.font.text1.lineHeight};
      @media only screen and (${theme.newTheme.breakpoints.tablet}) {
        font-size: ${theme.newTheme.font.text2.size};
        line-height: ${theme.newTheme.font.text2.lineHeight};
      }
      @media only screen and (${theme.newTheme.breakpoints.mobile}) {
        font-size: ${theme.newTheme.font.text2.size};
        line-height: ${theme.newTheme.font.text2.lineHeight};
      }
    `,
  }[size]);

const bodyParagraphSize = (size, theme) =>
  ({
    large: css`
      font-size: ${theme.newTheme.font.text1.size};
      line-height: ${theme.newTheme.font.text1.lineHeight};
      @media only screen and (${theme.newTheme.breakpoints.mobile}) {
        font-size: ${theme.newTheme.font.text3.size};
        line-height: ${theme.newTheme.font.text2.lineHeight};
      }
    `,

    small: css`
      font-size: ${theme.newTheme.font.text2.size};
      line-height: ${theme.newTheme.font.text2.lineHeight};
      @media only screen and (${theme.newTheme.breakpoints.mobile}) {
        font-size: ${theme.newTheme.font.text4.size};
        line-height: ${theme.newTheme.font.text4.lineHeight};
      }
    `,
  }[size]);

export const Content = styled.li`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  button {
    padding: 0;
  }
`;

export const HeaderTitle = styled.h4`
  margin: 0;
  max-width: 75%;
  padding: ${({ theme }) => `${theme.spacing.large} 0`};
  font-weight: ${({ theme }) => theme.fonts.weights.light};
  color: ${({ theme }) => theme.fonts.colors.primary};
  ${({ size, theme }) => titleSize(size, theme)};
  transition: ${({ theme }) => theme.transition};
  overflow: hidden;

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    ${({ theme }) => `padding: ${theme.spacing.medium} 0`};
    max-width: 80%;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    ${({ theme }) => `padding: ${theme.spacing.medium} 0`};
    max-width: 80%;
  }
`;

export const Container = styled.div`
  height: 100%;
  position: relative;
`;

export const Body = styled.div`
  width: 100%;
  max-height: ${({ show }) => (show ? '100%' : '0')};
  transition: ${({ theme }) => theme.transition};
  overflow: hidden;
`;

export const BodyDesc = styled.p`
  animation: ${({ fade }) => (fade ? 'fadeInDown .2s linear' : 'fadeInUp .2s linear')};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.light};
  transition: ${({ theme }) => theme.transition};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.largest};
  ${({ size, theme }) => bodyParagraphSize(size, theme)};

  ul {
    li {
      list-style: disc;
    }
  }

  b {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  }

  u {
    text-decoration: underline;
  }

  i {
    font-style: italic;
  }

  ol {
    list-style-type: decimal;
    li::marker {
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
      color: ${({ theme }) => theme.newTheme.color.blogBold};
    }
  }

  p {
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.colors.grey};
    transition: ${({ theme }) => theme.transition};
  }

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-40px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(-40px);
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    ${({ theme }) => `padding: ${theme.spacing.small} 0`};
    margin-bottom: ${({ theme }) => theme.spacing.medium};

    p {
      line-height: ${({ theme }) => theme.spacing.large};
    }
  }
`;
