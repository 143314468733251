import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import MetaTags from 'core/meta-tags/index';

import Layout from 'core/layout';
import PageHeader from 'atomic-design/atoms/page-header';
import FaqList from 'atomic-design/organisms/faq-list';
import CallToAction from 'atomic-design/molecules/call-to-action';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderLineBreak } from '../common/string-helpers';
import MainContainer from 'atomic-design/atoms/main-container';
import PageSection from 'atomic-design/atoms/page-section';
import theme from 'styles/theme';
import PageHeaderContainer from '../styles/pages/faqs-styles';

export const faqListQuery = graphql`
  query faqListQuery {
    allContentfulPage(filter: { url: { eq: "faqs" } }) {
      edges {
        node {
          headerTitle {
            json
          }
          headerSubtitle
          headerDescription {
            headerDescription
          }
          metatagImage {
            file {
              url
            }
          }
          metatagTitle
          metatagDescription
        }
      }
    }

    allContentfulFaq(sort: { fields: order, order: ASC }) {
      edges {
        node {
          title
          description {
            json
          }
          tags
          order
        }
      }
    }
  }
`;

const FaqPage = ({ data, location }) => {
  const faqList = data.allContentfulFaq.edges;
  const pageHeaderData = data.allContentfulPage.edges[0].node;
  const metatagImage = data.allContentfulPage.edges[0].node.metatagImage.file.url;
  const metatagDescription = data.allContentfulPage.edges[0].node.metatagDescription;
  const metatagTitle = data.allContentfulPage.edges[0].node.metatagTitle;

  return (
    <Layout path={location.pathname}>
      <MetaTags
        title={metatagTitle}
        description={metatagDescription}
        charSet="utf-8"
        image={metatagImage}
        viewport="width=device-width, initial-scale=1"
        type="website"
      />
      <MainContainer>
        <PageHeaderContainer>
          <PageHeader
            title={pageHeaderData.headerSubtitle}
            subTitle={documentToReactComponents(pageHeaderData.headerTitle.json)}
            description={renderLineBreak(pageHeaderData.headerDescription.headerDescription)}
          />
        </PageHeaderContainer>
        <PageSection containerSize="fluid" padding={`0 0 ${theme.newTheme.spacing(20)} 0`}>
          <FaqList faqList={faqList} />
          <CallToAction
            sectionTitle="Something else on your mind?"
            buttonTitle="Ask away"
            to="/contact"
          />
        </PageSection>
      </MainContainer>
    </Layout>
  );
};

FaqPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default FaqPage;
