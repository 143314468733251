import React from 'react';
import PropTypes from 'prop-types';

import MainContainer from 'atomic-design/atoms/main-container';
import Section from './styles';

const PageSection = ({
  className,
  containerSize,
  background,
  padding,
  mobilePadding = padding,
  margin,
  mobileMargin = margin,
  children,
  options,
}) => {
  return (
    <Section
      background={background}
      padding={padding}
      margin={margin}
      mobileMargin={mobileMargin}
      mobilePadding={mobilePadding}
      className={className}
      options={options}
    >
      <MainContainer size={containerSize}>{children}</MainContainer>
    </Section>
  );
};

PageSection.propTypes = {
  background: PropTypes.string,
  containerSize: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  children: PropTypes.node.isRequired,
  mobileMargin: PropTypes.string,
  mobilePadding: PropTypes.string,
  options: PropTypes.string,
};

PageSection.defaultProps = {
  background: '',
  containerSize: 'sm',
  padding: '0',
  margin: '0',
  options: 'default',
};

export default PageSection;
