import styled from 'styled-components';

const PageHeaderContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.newTheme.spacing(12)};

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding-bottom: ${({ theme }) => theme.newTheme.spacing(6)};
  }
`;

export default PageHeaderContainer;
