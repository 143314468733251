import React from 'react';
import PropTypes from 'prop-types';

import TaglistContainer from './styles';
import TaglistItem from './tag-list-item';

const Taglist = ({ list, tagSelected, setTagSelected }) => {
  return (
    <TaglistContainer>
      {list.map(item => (
        <TaglistItem
          key={item}
          item={item}
          selected={tagSelected === item}
          onClick={() => setTagSelected(item)}
        />
      ))}
    </TaglistContainer>
  );
};

Taglist.propTypes = {
  list: PropTypes.array.isRequired,
  tagSelected: PropTypes.string.isRequired,
  setTagSelected: PropTypes.func.isRequired,
};

export default Taglist;
