import styled from 'styled-components';

const TaglistContainer = styled.ul`
  padding: 0;
  list-style: none;
  margin: ${({ theme }) => `${theme.spacing.large} 0`};

  li:last-child {
    margin-right: 0;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin: 0;
  }
`;

export default TaglistContainer;
