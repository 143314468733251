import React from 'react';
import PropTypes from 'prop-types';

import ListDivider from 'atomic-design/atoms/list-divider';
import AccordionItem from './accordion-item';
import AccordionList from './styles';

const Accordion = ({ list, size }) => {
  const lastIndex = list.slice(-1)[0];
  return (
    <AccordionList>
      {list.map((item, i) =>
        lastIndex !== list[i] ? (
          <>
            <AccordionItem size={size} key={item.title} item={item} children={item.children} />
            <ListDivider />
          </>
        ) : (
          <AccordionItem size={size} key={item.title} item={item} children={item.children}/>
        )
      )}
    </AccordionList>
  );
};

Accordion.propTypes = {
  list: PropTypes.array.isRequired,
  size: PropTypes.string,
};

Accordion.defaultProps = {
  size: 'large',
};

export default Accordion;
