import React from 'react';
import PropTypes from 'prop-types';

import Button from 'atomic-design/atoms/button';
import Link from 'atomic-design/atoms/link';
import { ContainerCallToAction, CallToActionSectionTitle } from './styles';

const CallToAction = ({ sectionTitle, buttonTitle, to }) => {
  return (
    <>
      <ContainerCallToAction>
        {sectionTitle && <CallToActionSectionTitle>{sectionTitle}</CallToActionSectionTitle>}
        <Link to={to}>
          <Button aria-label="link to page">{buttonTitle}</Button>
        </Link>
      </ContainerCallToAction>
    </>
  );
};

CallToAction.propTypes = {
  sectionTitle: PropTypes.string,
  buttonTitle: PropTypes.string.isRequired,
  to: PropTypes.string,
};

CallToAction.defaultProps = {
  to: '/#!',
};

export default CallToAction;
