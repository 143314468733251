import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import AccordionButtonIcon from 'atomic-design/molecules/accordion/accordion-button-icon';
import Button from 'atomic-design/atoms/button';
import { Content, Header, Body, HeaderTitle, BodyDesc, Container } from './styles';

const AccordionItem = ({ item, size, children }) => {
  const { title, description } = item;
  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  const bodyDescription =
    item && description.json ? documentToReactComponents(description.json) : description;

  return (
    <Content>
      <Header onClick={handleClick}>
        <HeaderTitle size={size}>{item && title}</HeaderTitle>
        <Button variant="icon" ariaLabel={show ? 'open' : 'close'}>
          <AccordionButtonIcon openClose={!show} />
        </Button>
      </Header>
      <Body show={show}>
        <Container>
          <BodyDesc size={size} fade={show}>
            {bodyDescription}
            {children && children}
          </BodyDesc>
        </Container>
      </Body>
    </Content>
  );
};

AccordionItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    children: PropTypes.any,
  }).isRequired,
  size: PropTypes.string,
};

AccordionItem.defaultProps = {
  size: 'large',
};

export default AccordionItem;
