import React from 'react';
import PropTypes from 'prop-types';

import theme from 'styles/theme';

import {
  ContainerPageHeader,
  PageHeaderTitle,
  PageHeaderSubtitle,
  PageHeaderDescription,
} from './styles';

const PageHeader = ({ title, subTitle, description, color }) => {
  return (
    <ContainerPageHeader>
      <PageHeaderTitle>{title}</PageHeaderTitle>
      <PageHeaderSubtitle color={color}>{subTitle}</PageHeaderSubtitle>
      {description && <PageHeaderDescription>{description}</PageHeaderDescription>}
    </ContainerPageHeader>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string,
};

PageHeader.defaultProps = {
  color: theme.newTheme.color.gradient.text,
};

export default PageHeader;
