import styled, { css } from 'styled-components';

const optionsStyles = options =>
  ({
    fullWidth: css`
      width: 100vw;
      left: calc(-50vw + 50%);
    `,
    default: css`
      width: 100%;
    `,
  }[options]);

const Section = styled.section`
  overflow: hidden;
  position: relative;
  background: ${({ background }) => background};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  ${({ options }) => optionsStyles(options)};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin: ${({ mobileMargin }) => mobileMargin};
    padding: ${({ mobilePadding }) => mobilePadding};
  }
`;

export default Section;
